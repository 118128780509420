<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <System_config />
      </v-col>

      <v-col cols="12" md="12">
        <Api_connect/>
      </v-col>


    </v-row>
  </v-container>
</template>
<script>
import Api_connect from "../../components/admin/api_connect.vue";
import System_config from "../../components/admin/system_config.vue";

export default {
  components: { System_config,Api_connect },
  data() {
    return {
      ApiKey: "HRvec2021",
      user: []
    };
  },
  async mounted() {
    await this.userQueryAll();
  },
  methods: {
    async userQueryAll() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.user = result.data;
      if(this.user.userauthorityAdmin != 1){
        Swal.fire({
            icon: "error",
            title: 'คุณไม่ได้รับสิทธิ์ในส่วนงานตั้งค่านี้',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(async result => {
            if (result.isConfirmed) {
              this.$router.push("/admin");
            }
          });

  
        
      }
    }
  }
};
</script>
