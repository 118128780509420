<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="API Connect"
        class="px-5 py-3"
      >

      <v-card class="mb-4 pa-2" outlined>
          <v-row>        
            <v-col cols="12" lg="12" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="api_connectAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="api_connects"
          :search="search"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
          </template>

         

          <template v-slot:item.api_connect_latest="{ item }">
            <div>
              {{
                item.api_connect_latest
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.api_connect_status="{ item }">
            <div class="text-center">
              <v-icon
                size="30"
                color="success"
                v-if="item.api_connect_status === '1'"
                >mdi-lightbulb-on</v-icon
              >
              <v-icon size="30" v-else-if="item.api_connect_status === '0'"
                >mdi-lightbulb-off</v-icon
              >
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="api_connectEdit(item.api_connect_id)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editapi_connectdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editapi_connectdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลตั้งค่าประเภทงบประมาณ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editapi_connectform" lazy-validation>
                <v-container grid-list-md>
                             <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      label="X-Office-Request"
                      v-model="editapi_connect.api_connect_user"
                      prepend-icon="mdi-file-document"
                      required
                      :rules="[(v) => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex md12>
                    <v-text-field
                      label="X-Office-Key"
                      v-model="editapi_connect.api_connect_key"
                      prepend-icon="mdi-file-document"
                      required
                      :rules="[(v) => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex md12>
                    <v-radio-group
                      v-model="editapi_connect.api_connect_status"
                      row
                      :rules="[(v) => !!v || '']"
                    >
                      <template v-slot:label>
                        <div>Status <strong> API</strong></div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div><strong class="success--text">Open</strong></div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">Close</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>


                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click.stop="editapi_connectdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn                
                color="warning"
                @click.stop="editapi_connectSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไข
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>



        <!--addapi_connectdialog  -->
        <v-layout row justify-center>
        <v-dialog v-model="addapi_connectdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลตั้งค่าประเภทงบประมาณเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addapi_connectform" lazy-validation>
                <v-container grid-list-md>               


                  <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      label="X-Office-Request"
                      v-model="addapi_connect.api_connect_user"
                      prepend-icon="mdi-file-document"
                      required
                      :rules="[(v) => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex md12>
                    <v-text-field
                      label="X-Office-Key"
                      v-model="addapi_connect.api_connect_key"
                      prepend-icon="mdi-file-document"
                      required
                      :rules="[(v) => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex md12>
                    <v-radio-group
                      v-model="addapi_connect.api_connect_status"
                      row
                      :rules="[(v) => !!v || '']"
                    >
                      <template v-slot:label>
                        <div>Status <strong> API</strong></div>
                      </template>
                      <v-radio value="1">
                        <template v-slot:label>
                          <div><strong class="success--text">Open</strong></div>
                        </template>
                      </v-radio>
                      <v-radio value="0">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">Close</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addapi_connectdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addapi_connectSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      editapi_connectdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      api_connects: [],
      editapi_connect: {},
      search: "",
      pagination: {},
      headers: [
        { text: "User", align: "left", value: "api_connect_user" },
        { text: "Key", align: "center", value: "api_connect_key" },
        { text: "Count", align: "center", value: "api_connect_count" },
        { text: "Latest", align: "center", value: "api_connect_latest" },
        { text: "On/Off", align: "center", value: "api_connect_status" },

        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      typeBudget: [
        { text: "เงินงบประมาณ", value: "เงินงบประมาณ" },
        { text: "เงินนอกงบประมาณ", value: "เงินนอกงบประมาณ" }
      ],
      addapi_connect:{},
      addapi_connectdialog:false,
    };
  },
  async mounted() {
    this.api_connectQueryAll();
  },
  methods: {
    async api_connectAdd() {
      this.addapi_connect = {};
      this.addapi_connectdialog = true;
    },

    async addapi_connectSubmit(){
      if (this.$refs.addapi_connectform.validate()) {
        this.addapi_connect.ApiKey = this.ApiKey      
        this.addapi_connect.api_connect_count =0      
        this.addapi_connect.api_connect_latest = new Date()    
        let result = await this.$http.post(
          'api_connect.insert.php',
          this.addapi_connect
        )

        if (result.data.status == true) {
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการลบข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          await this.api_connectQueryAll()
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.addapi_connectdialog = false
      }
    },

    async api_connectQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("api_connect.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.api_connects = result.data;
    },

    async api_connectEdit(api_connect_id) {
      let result = await this.$http.post("api_connect.php", {
        ApiKey: this.ApiKey,
        api_connect_id: api_connect_id
      });
      this.editapi_connect = result.data;
      this.editapi_connectdialog = true;
    },
    async editapi_connectSubmit() {
      if (this.$refs.editapi_connectform.validate()) {
        this.editapi_connect.ApiKey = this.ApiKey;
        this.editapi_connect.api_connect_latest = new Date();
        let result = await this.$http.post(
          "api_connect.update.php",
          this.editapi_connect
        );
        if (result.data.status == true) {
          this.api_connect = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "แก้ไขข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.api_connectQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "แก้ไขข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.editapi_connectdialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
