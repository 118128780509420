<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ตั้งค่าระบบ HRVEC"
        class="px-5 py-3"
      >
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="system_configs"
          :search="search"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
          </template>

          <template v-slot:item.system_config_start="{ item }">
            <div>
              {{
                item.system_config_start
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.system_config_stop="{ item }">
            <div>
              {{
                item.system_config_stop
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.system_config_status="{ item }">
            <div class="text-center">
              <v-icon
                size="30"
                color="success"
                v-if="item.system_config_status === '0'"
                >mdi-lightbulb-on</v-icon
              >
              <v-icon size="30" v-else-if="item.system_config_status === '1'"
                >mdi-lightbulb-off</v-icon
              >
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="system_configEdit(item.system_config_id)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editsystem_configdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editsystem_configdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลตั้งค่าประเภทงบประมาณ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editsystem_configform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-text-field
                        label="วันที่เริ่มปิด"
                        v-model="editsystem_config.system_config_start"
                        require
                        type="date"
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        label="วันที่สิ้นสุดการปิด"
                        type="date"

                        v-model="editsystem_config.system_config_stop"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-radio-group
                        v-model="editsystem_config.system_config_status"
                        :rules="[v => !!v || '']"
                        row
                      >
                        <template v-slot:label>
                          <div><strong>เปิด/ปิด</strong></div>
                        </template>
                        <v-radio value="0">
                          <template v-slot:label>
                            <div>
                              <strong class="success--text">เปิดระบบ</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="1">
                          <template v-slot:label>
                            <div><strong class="error--text">ปิดระบบ</strong></div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click.stop="editsystem_configdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn                
                color="warning"
                @click.stop="editsystem_configSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไข
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      editsystem_configdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      system_configs: [],
      editsystem_config: {},
      search: "",
      pagination: {},
      headers: [
        { text: "รายการ", align: "left", value: "system_config_topic" },
        { text: "เงือนไข", align: "center", value: "system_config_fig" },
        { text: "วันที่เริ่ม", align: "center", value: "system_config_start" },
        { text: "วันที่สิ้นสุด", align: "center", value: "system_config_stop" },
        { text: "เปิด/ปิด", align: "center", value: "system_config_status" },

        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      typeBudget: [
        { text: "เงินงบประมาณ", value: "เงินงบประมาณ" },
        { text: "เงินนอกงบประมาณ", value: "เงินนอกงบประมาณ" }
      ]
    };
  },
  async mounted() {
    this.system_configQueryAll();
  },
  methods: {
    async system_configQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("system_config.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.system_configs = result.data;
    },

    async system_configEdit(system_config_id) {
      let result = await this.$http.post("system_config.php", {
        ApiKey: this.ApiKey,
        system_config_id: system_config_id
      });
      this.editsystem_config = result.data;
      this.editsystem_configdialog = true;
    },
    async editsystem_configSubmit() {
      if (this.$refs.editsystem_configform.validate()) {
        this.editsystem_config.ApiKey = this.ApiKey;
        this.editsystem_config.system_config_fig = new Date();
        let result = await this.$http.post(
          "system_config.update.php",
          this.editsystem_config
        );
        if (result.data.status == true) {
          this.system_config = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "แก้ไขข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.system_configQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "แก้ไขข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.editsystem_configdialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
